import AccessDefinitions from 'security/AccessDefinitions';
import AirdomeStore from '../../stores/AirdomeStore';
import ChangeRequestHubConnection from './ChangeRequestHubConnection';
import { EventEmitter } from 'events';
import NotificationSeverity from '../../dto/notification/NotificationSeverity';
import NotificationSeverityIcon from '../../components/notifications/NotificationSeverityIcon';
import NotificationsChannelHubConnection from './NotificationsChannelHubConnection';
import PlcNotificationHubConnection from './PlcNotificationsHubConnection';
import React from 'react';
import RegistryMultiAirdomeHubConnection from './RegistryMultiAirdomeHubConnection';
import SecurityStore from '../../stores/SecurityStore';
import StatusMultiAirdomeHubConnection from './StatusMultiAirdomeHubConnection';
import StatusSingleAirdomeHubConnection from './StatusSingleAirdomeHubConnection';
import Translate from '../../localization/Translate';
import accessDefinitions from 'security/AccessDefinitions';

interface UINotificationserviceParams {
  registryMultiAirdomeHubConnection: RegistryMultiAirdomeHubConnection;
  notificationsChannelHubConnection: NotificationsChannelHubConnection;
  statusMultiAirdomeHubConnection: StatusMultiAirdomeHubConnection;
  statusSingleAirdomeHubConnection: StatusSingleAirdomeHubConnection;
  plcNotificationsHubConnection: PlcNotificationHubConnection;
  changeRequestHubConnection: ChangeRequestHubConnection;
  airdomeStore: AirdomeStore;
  securityStore: SecurityStore;
}

interface UINotificationProps {
  color?: 'primary' | 'success' | 'danger' | 'warning' | 'black';
  title?: string;
  message: string;
}

interface UIImageNotificationProps extends UINotificationProps {
  image: React.ReactNode;
}

export type GetNotification = (translate: Translate) => UINotificationProps;
export type GetImageNotification = (translate: Translate) => UIImageNotificationProps;

export default class UINotificationService {
  public static readonly NotificationCreated = 'NotificationCreated';
  public static readonly ImageNotificationCreated = 'ImageNotificationCreated';
  private eventEmitter: EventEmitter = new EventEmitter();

  constructor(params: UINotificationserviceParams) {
    this.setupEventHandlers(params);
    this.setupChangeRequestEventHandlers(params);
  }

  public onNotification = (callback: (getNotification: GetNotification) => void) => {
    this.eventEmitter.on(UINotificationService.NotificationCreated, callback);
  }

  public onImageNotification = (callback: (getNotification: GetImageNotification) => void) => {
    this.eventEmitter.on(UINotificationService.ImageNotificationCreated, callback);
  }

  public emitNotification = (getNotification: GetNotification) => {
    this.eventEmitter.emit(UINotificationService.NotificationCreated, getNotification);
  }

  public emitImageNotification = (getNotification: GetImageNotification) => {
    this.eventEmitter.emit(UINotificationService.ImageNotificationCreated, getNotification);
  }

  private setupChangeRequestEventHandlers = (params: UINotificationserviceParams) => {
    params.changeRequestHubConnection.onCreated((airdomeId, createdBy, chRType) => {
      if (!this.shouldShowNotificationToUser(params, airdomeId))
        return;

      const airdome = params.airdomeStore.airdomes.find(x => x.id === airdomeId);

      if (!airdome)
        return;

      this.emitNotification((translate: Translate) => {
        const type = translate(t => t.enums.changeRequest[chRType]);

        return {
          color: 'primary',
          title: translate(x => x.notifications.changeRequest.created.title, { airdomeName: airdome.name }),
          message: translate(x => x.notifications.changeRequest.created.message, { author: createdBy?.userName, type })
        };
      });
    });

    params.changeRequestHubConnection.onFinished((airdomeId, createdBy, chRType) => {
      if (!this.shouldShowNotificationToUser(params, airdomeId))
        return;

      const airdome = params.airdomeStore.airdomes.find(x => x.id === airdomeId);

      if (!airdome)
        return;

      this.emitNotification((translate: Translate) => {
        const type = translate(t => t.enums.changeRequest[chRType]);

        return {
          color: 'success',
          title: translate(x => x.notifications.changeRequest.finished.title, { airdomeName: airdome.name }),
          message: translate(x => x.notifications.changeRequest.finished.message, { author: createdBy?.userName, type })
        };
      });
    });

    params.changeRequestHubConnection.onFailed((airdomeId, createdBy, chRType) => {
      if (!this.shouldShowNotificationToUser(params, airdomeId))
        return;

      const airdome = params.airdomeStore.airdomes.find(x => x.id === airdomeId);

      if (!airdome)
        return;

      this.emitNotification((translate: Translate) => {
        const type = translate(t => t.enums.changeRequest[chRType]);

        return {
          color: 'danger',
          title: translate(x => x.notifications.changeRequest.failed.title, { airdomeName: airdome.name }),
          message: translate(x => x.notifications.changeRequest.failed.message, { author: createdBy?.userName, type })
        };
      });
    });
  }

  private setupEventHandlers = (params: UINotificationserviceParams) => {
    if (params.securityStore.isAccessAllowed(AccessDefinitions.Airdomes.Manage)) {
      params.registryMultiAirdomeHubConnection.onAirdomeCreated((airdome) => {
        if (params.airdomeStore.SelectedAirdomeId === undefined) {
          this.emitNotification((translate: Translate) => ({
            color: 'primary',
            title: translate(x => x.notifications.airdome.created.title, { airdomeName: airdome.name }),
            message: translate(x => x.notifications.airdome.created.message)
          }));
        }
      });
    }

    params.notificationsChannelHubConnection.onNotificationChannelUpdateTimedOut((userId) => {
      this.emitNotification((translate: Translate) => ({
        color: 'danger',
        title: translate(x => x.notifications.notificationChannelTimedOut.title),
        message: translate(x => x.notifications.notificationChannelTimedOut.message)
      }));
    });

    params.statusMultiAirdomeHubConnection.onConnectionStateChanged((airdomeId, newState) => {
      if (!this.shouldShowNotificationToUser(params, airdomeId))
        return;

      const airdome = params.airdomeStore.airdomes.find(x => x.id === airdomeId);

      if (!airdome)
        return;

      this.emitNotification((translate: Translate) => ({
        color: this.getNotificationColorByState(newState),
        title: translate(x => x.notifications.airdome.stateChanged.title),
        message: this.getStateMessage(translate, newState, airdome.name),
      }));
    });

    params.plcNotificationsHubConnection.onNotificationOccurred((airdomeId, notification) => {
      if (!this.shouldShowNotificationToUser(params, airdomeId))
        return;

      const airdome = params.airdomeStore.airdomes.find(x => x.id === airdomeId);
      if (!airdome)
        return;

      this.emitImageNotification((translate: Translate) => ({
        image: <NotificationSeverityIcon severity={notification.severity} />,
        color: this.getNotificationColorBySeverity(notification.severity),
        title: airdome.name,
        message: `${notification.description} - ${notification.value}`
      }));
    });
  }

  private shouldShowNotificationToUser = (params: UINotificationserviceParams, airdomeId: number) =>
    !params.securityStore.isAccessAllowed(accessDefinitions.Airdomes.Manage)
    || params.airdomeStore.SelectedAirdomeId === airdomeId

  private getNotificationColorByState = (state: string) => {
    switch (state.toLowerCase()) {
      case 'alive': return 'success';
      case 'halfdead': return 'warning';
      case 'dead': return 'danger';
      case 'plcunavailable': return 'black';
    }
  }

  private getNotificationColorBySeverity = (severity: NotificationSeverity) => {
    switch (severity) {
      case NotificationSeverity.Information: return 'primary';
      case NotificationSeverity.Warning: return 'warning';
      case NotificationSeverity.Error: return 'danger';
    }
  }

  private getStateMessage = (translate: Translate, state: string, airdomeName: string) => {
    return translate(x => x.notifications.airdome.stateChanged.messageFormat[state.toLowerCase()], { airdomeName });
  }
}
