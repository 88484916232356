import { getJoinedEndpoint } from './../../utils/Settings';
import { AxiosInstance } from 'axios';
import { getBackendException } from '../../helpers/ErrorHelper';
import {
  StatusAirdomeDto
} from '../../dto/status';

export default class StatusAirdomeService {
  private axios: AxiosInstance;
  private getAirdomesUrl: string;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.getAirdomesUrl = getJoinedEndpoint(x => x.status.baseUri, x => x.status.airdomes.getAll);
  }

  public async getAirdomes(trackingId: string): Promise<StatusAirdomeDto[]> {
    try {
      const response = await this.axios.get<StatusAirdomeDto[]>(
        this.getAirdomesUrl, { headers: { trackingId } });

      return response.data;
    } catch (error) {
      throw getBackendException(error);
      // TODO: #4623 log something
    }
  }
}
